import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import NProgress from 'nprogress';
import '@/assets/css/progress.min.css';

import store from "@/store";
import { ROLES } from "@/utils/enums";
import { MAIN_ROUTES, OWNER_ROUTES } from "./routes";

const routes: Array<RouteRecordRaw> = [
  ...MAIN_ROUTES,
  ...OWNER_ROUTES,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);
  if (to.name) NProgress.start();

  await store.dispatch('checkAuthorization');
  store.commit('destroyModal');
  document.title = to.meta.title as string;

  const user = store.getters.user;
  if (to.matched.some((route) => ['telegram', 'access', 'newyear'].includes(route.meta.layout as string))) return next();

  if (to.matched.some((route) => !route.meta.requiredAuth)) {
    if (!user) return next();
    return next(user.role === ROLES.OWNER ? '/owner' : '/channel');
  }
  
  if (!user) return next('/auth');
  if (to.matched.some((route) => route.meta.requiredOwner))
    return user.role === ROLES.OWNER ? next() : next('/channel');
  if (to.matched.some((route) => route.meta.requiredUser))
    return user.role === ROLES.USER ? next() : next('/owner');

  next();
});

router.afterEach(() => NProgress.done());

export default router;
