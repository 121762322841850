
import { defineComponent, defineAsyncComponent } from "vue-demi";

const MainLayout = defineAsyncComponent(() => import('./layouts/MainLayout.vue'));
const OwnerLayout = defineAsyncComponent(() => import('./layouts/OwnerLayout.vue'));
const AuthLayout = defineAsyncComponent(() => import('./layouts/AuthLayout.vue'));
const TelegramLayout = defineAsyncComponent(() => import('./layouts/TelegramLayout.vue'));
const CardLayout = defineAsyncComponent(() => import('./layouts/CardLayout.vue'));
const Notification = defineAsyncComponent(() => import('./components/common/notification/index.vue'));

export default defineComponent({
  data() {
    return {
      timeout: 0,
    }
  },
  computed: {
    layout() {
      const isRequiredAuth = this.$route.meta.layout || 'auth';
      return isRequiredAuth + "-layout";
    },
  },
  components: {
    MainLayout,
    AuthLayout,
    OwnerLayout,
    TelegramLayout,
    CardLayout,
    Notification
  }
})
