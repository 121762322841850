interface State {
  activity: boolean,
  activityTitle: string,
  activityInterval: number | null,
}

export default {
  state: {
    activity: true,
    activityTitle: '',
    activityInterval: null,
  },
  mutations: {
    activitySite(state: State, status: boolean) {
      state.activity = status;
      if (!state.activity) return;

      clearInterval(state.activityInterval as number);
      state.activityInterval = null;
      document.title = state.activityTitle;
    },
    activitySetTitle(state: State, title: string) {
      document.title = state.activityTitle = title;
    },
    activityMessage(state: State) {
      if (state.activity) return;
      if (state.activityInterval) return;
      state.activityInterval = setInterval(() => {
        document.title = document.title === state.activityTitle ? 'Новое сообщение' : state.activityTitle;
      }, 1000);
    }
  },
  actions: {},
  getters: {
    activity: (state: State) => state.activity,
  }
}