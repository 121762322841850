import { dynamicsObject } from "@/interfaces";
import axios from "axios";

export default {
  subscriptionListMethod: async (token: string) => {
    const request = axios.create();
    return await request({
      url: '/api/subscriber/subscription',
      method: 'GET',
      headers: {
        Authorization: token
      }
    });
  },
  channelLinkMethod: async (token: string, channel: string) => {
    const request = axios.create();
    return await request({
      url: '/api/subscriber/send/link',
      method: 'POST',
      headers: {
        Authorization: token
      },
      data: {
        channel
      }
    });
  },
  extendSubscribeMethod: async (token: string, channel: string) => {
    const request = axios.create();
    return await request({
      url: '/api/subscriber/extend',
      method: 'POST',
      headers: {
        Authorization: token
      },
      data: {
        channel
      }
    });
  },
  removeAutoPaymentMethod: async (token: string, channel: string) => {
    const request = axios.create();
    return await request({
      url: '/api/subscriber/autopayment',
      method: 'POST',
      headers: {
        Authorization: token
      },
      data: {
        channel
      }
    });
  },
  paymentMethod: async (token: string, tariff: string, code: string) => {
    const request = axios.create();
    return await request({
      url: '/api/subscriber/payment',
      data: { tariff, code },
      method: 'POST',
      headers: {
        Authorization: token
      }
    });
  },
  updateRequestMethod: async (token: string, data: dynamicsObject) => {
    const request = axios.create();
    return await request({
      url: '/api/subscriber/request',
      data,
      method: 'PATCH',
      headers: {
        Authorization: token
      }
    });
  },
};
