import axios from "axios";

export default {
  checkMethod: async (token: string, tariff: string, code: string) => {
    const request = axios.create();
    return await request({
      url: '/api/promocode/apply',
      data: { code, tariff },
      method: 'POST',
      headers: {
        Authorization: token
      }
    });
  },
};
