export default {
  auth: {
    auth: { title: 'Авторизация', layout: 'auth' },
    access: { title: 'Авторизация', layout: 'access' },
    telegram: { title: 'Авторизация - Telegram', layout: 'auth' },
  },
  moderator: {
    main: { title: 'Модераторы', id: 'moderator', section: 'moderator', requiredAuth: true, requiredUser: true, layout: 'main' },
  },
  profile: {
    main: { title: 'Личные данные', id: 'profile', section: 'user-profile', requiredAuth: true, requiredUser: true, layout: 'main' },
    settings: { title: 'Настройки', id: 'profile-setting', section: 'user-profile', requiredAuth: true, requiredUser: true, layout: 'main' },
  },
  channel: {
    list: { title: 'Мои сообщества', id: 'channel-list', section: 'user-channel', requiredAuth: true, requiredUser: true, layout: 'main' },
    create: { title: 'Создание сообщества', id: 'channel-create', section: 'user-channel', requiredAuth: true, requiredUser: true, layout: 'main' },
    main: { title: 'Сообщество', id: 'channel-main', section: 'user-channel', requiredAuth: true, back: '/channel', requiredUser: true, layout: 'main' },
    settings: { title: 'Настройки сообщества', id: 'channel-settings', section: 'user-channel', requiredAuth: true, back: '/channel', backName: true, requiredUser: true, layout: 'main' },
  },
  subscriber: {
    list: { title: 'Подписчики', id: 'subscriber-list', section: 'user-subscriber', requiredAuth: true, requiredUser: true, layout: 'main' },
  },
  tariff: {
    list: { title: 'Тарифы', id: 'tariff-list', section: 'user-tariff', requiredAuth: true, requiredUser: true, layout: 'main' },
    main: { title: 'Настройки тарифа', id: 'tariff-main', section: 'user-tariff', requiredAuth: true, requiredUser: true, layout: 'main' },
  },
  transaction: {
    list: { title: 'Транзакции', id: 'transaction-list', section: 'user-transaction', requiredAuth: true, requiredUser: true, layout: 'main' },
  },
  payment: {
    // main: { title: 'Оплата', id: 'payment-main', section: 'user-payment', requiredAuth: true, requiredUser: true, layout: 'main' },
    details: { title: 'Платежные данные', id: 'payment-details', section: 'user-payment', requiredAuth: true, requiredUser: true, layout: 'main' },
    tinkoff: { title: 'Оплата подписки', layout: 'telegram', name: 'BotPay.Оплата' },
  },
  autopost: {
    list: { title: 'Автопостинг', id: 'autopost-list', section: 'user-autopost', requiredAuth: true, requiredUser: true, layout: 'main' },
    main: { title: 'Автопостинг', id: 'autopost-main', section: 'user-autopost', requiredAuth: true, requiredUser: true, layout: 'main' },
  },
  promocode: {
    main: { title: 'Промокоды', id: 'promocode-main', section: 'user-promocode', requiredAuth: true, requiredUser: true, layout: 'main' },
    generation: { title: 'Генерация промокодов', id: 'promocode-generation', section: 'user-promocode', requiredAuth: true, requiredUser: true, layout: 'main' },
  },
  mailing: {
    list: { title: 'Рассылки', id: 'mailing-list', section: 'user-mailing', requiredAuth: true, requiredUser: true, layout: 'main' },
  },
  partner: {
    main: { title: 'Партнёрская программа', id: 'partner-main', section: 'user-partner', requiredAuth: true, requiredUser: true, layout: 'main' },
  },
  referral: {
    main: { title: 'Реферальная программа', id: 'referral-main', section: 'user-referral', requiredAuth: true, requiredUser: true, layout: 'main' },
  },
  info: {
    policy: { title: 'Политика конфиденциальности', id: 'info-policy', section: 'user-info', requiredAuth: true, requiredUser: true, layout: 'main' },
    offer: { title: 'Договор оферты', id: 'info-offer', section: 'user-info', requiredAuth: true, requiredUser: true, layout: 'main' },
    legal: { title: 'Юридическая информация', id: 'info-legal', section: 'user-info', requiredAuth: true, requiredUser: true, layout: 'main' },
    policyPayment: { title: 'Правила оплаты и возврата', id: 'info-policy-payment', section: 'user-info', requiredAuth: true, requiredUser: true, layout: 'main' },
  },
  telegram: {
    payment: { title: 'Оплата подписки', layout: 'telegram', name: 'BotPay.Подписка' },
    success: { title: 'Успешная оплата', layout: 'telegram', name: 'BotPay.Подписка' },
    error: { title: 'Ошибка при оплате', layout: 'telegram', name: 'BotPay.Подписка' },
    subscription: { title: 'Мои подписки', layout: 'telegram', name: 'BotPay.Мои подписки' },
  },
  card: {
    confirmed: { title: 'Привязка карты', layout: 'card', name: 'Привязка карты' },
  },
  newyear: {
    index: { title: 'Новогодний опрос', layout: 'newyear' },
  },
  owner: {
    auth: { title: 'Авторизация', id: 'owner-auth', layout: 'auth', requiredOwner: true, requiredAuth: true },
    advertising: { title: 'Реклама', id: 'owner-advertising', section: 'owner-advertising', requiredOwner: true, requiredAuth: true, layout: 'owner' },
    main: { title: 'Главная', id: 'owner-main', section: 'owner-main', requiredOwner: true, requiredAuth: true, layout: 'owner' },
    admin: { title: 'Администраторы', id: 'admin-list', section: 'owner-admin', requiredOwner: true, requiredAuth: true, layout: 'owner' },
    channel: { title: 'Сообщества', id: 'channel-list', section: 'owner-channel', requiredOwner: true, requiredAuth: true, layout: 'owner' },
    subscriber: { title: 'Подписчики', id: 'subscriber-list', section: 'owner-subscriber', requiredOwner: true, requiredAuth: true, layout: 'owner' },
    tariff: { title: 'Тарифы', id: 'tariff-list', section: 'owner-tariff', requiredOwner: true, requiredAuth: true, layout: 'owner' },
    transaction: { title: 'Транзакции', id: 'transaction-list', section: 'owner-transaction', requiredOwner: true, requiredAuth: true, layout: 'owner' },
    promocode: { title: 'Промокоды', id: 'promocode-list', section: 'owner-promocode', requiredOwner: true, requiredAuth: true, layout: 'owner' },
    partner: { title: 'Партнерская программа', id: 'partner-list', section: 'owner-partner', requiredOwner: true, requiredAuth: true, layout: 'owner' },
    referral: { title: 'Реферальная программа', id: 'referral-list', section: 'owner-referral', requiredOwner: true, requiredAuth: true, layout: 'owner' },
    support: { title: 'Поддержка', id: 'support-list', section: 'owner-support', requiredOwner: true, requiredAuth: true, layout: 'owner' },
    order: { title: 'Заказы', id: 'order-list', section: 'owner-order', requiredOwner: true, requiredAuth: true, layout: 'owner' },
  }
}