export enum ROLES {
  OWNER = 'OWNER',
  USER = 'USER',
}

export enum STATUSES {
  OK = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  SERVER = 500,
}

export enum FILES_TYPES {
  IMAGE = 'IMAGE',
  DOCUMENT = 'DOCUMENT',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
};

export enum TARIFF_TYPES {
  DEFAULT = 'DEFAULT',
  DISPOSABLE = 'DISPOSABLE',
}

export enum ACTIVE_TYPES {
  ACTIVE = 'ACTIVE',
  UNACTIVE = 'UNACTIVE',
  VIEW = 'VIEW',
  KNOCKED = 'KNOCKED',
  BANNED = 'BANNED',
}

export enum TRANSACTION_TYPES {
  SUBSCRIBE = 'SUBSCRIBE',
  EXTENDED_SUBSCRIBE = 'EXTENDED_SUBSCRIBE',
  WITHDRAWAL = 'WITHDRAWAL',
  WITHDRAWAL_PARTNER = 'WITHDRAWAL_PARTNER',
  PARTNER_BONUS = 'PARTNER_BONUS',
  REPLENISHMENT = 'REPLENISHMENT',
  PAYMENT_TARIFF = 'PAYMENT_TARIFF',
  BONUS = 'BONUS',
  BURNED_BONUS = 'BURNED_BONUS',
}

export enum TRANSACTION_PAYMENT_METHOD {
  YANDEX = 'YANDEX',
  TINKOFF = 'TINKOFF',
  BOTPAY = 'BOTPAY',
  FREE = 'FREE',
}

export enum LEVEL_TYPES {
  FIRST = 'FIRST',
  SECOND = 'SECOND',
}

export enum ORDER_TYPES {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
}

export enum CHANNEL_QUIZ_QUESTION_TYPES {
  TEXT = 'TEXT',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  FILE = 'FILE'
}

export enum USER_SOURCE {
  'vc' = 'vc',
  'Прямой' = 'Прямой',
  'Поиск' = 'Поиск',
  'Внутренние переходы' = 'Внутренние переходы',
  'Неизвестно' = 'Неизвестно',
  'Из мессенджеров' = 'Из мессенджеров',
  'Переходы по ссылкам на сайтах' = 'Переходы по ссылкам на сайтах',
  'Яндекс директ' = 'Яндекс директ',
  'Гугл директ' = 'Гугл директ',
  'Инстаграм' = 'Инстаграм',
  'Вконтакте' = 'Вконтакте',
  'Рекламная кампания' = 'Рекламная кампания',
}

export const USER_SOURCE_DROP = [
  { _id: USER_SOURCE.vc, name: USER_SOURCE.vc },
  { _id: USER_SOURCE.Прямой, name: USER_SOURCE.Прямой },
  { _id: USER_SOURCE.Поиск, name: USER_SOURCE.Поиск },
  { _id: USER_SOURCE["Внутренние переходы"], name: USER_SOURCE["Внутренние переходы"] },
  { _id: USER_SOURCE.Неизвестно, name: USER_SOURCE.Неизвестно },
  { _id: USER_SOURCE["Из мессенджеров"], name: USER_SOURCE["Из мессенджеров"] },
  { _id: USER_SOURCE["Переходы по ссылкам на сайтах"], name: USER_SOURCE["Переходы по ссылкам на сайтах"] },
  { _id: USER_SOURCE["Яндекс директ"], name: USER_SOURCE["Яндекс директ"] },
  { _id: USER_SOURCE["Гугл директ"], name: USER_SOURCE["Гугл директ"] },
  { _id: USER_SOURCE["Инстаграм"], name: USER_SOURCE["Инстаграм"] },
  { _id: USER_SOURCE["Рекламная кампания"], name: USER_SOURCE["Рекламная кампания"] },
  { _id: USER_SOURCE.Вконтакте, name: USER_SOURCE.Вконтакте },
]