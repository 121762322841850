import { dynamicsObject } from "@/interfaces";
import axios, { AxiosRequestConfig } from "axios";

export default {
  getDiagramMethod: async (options: AxiosRequestConfig) => {
    return await axios(options);
  },
  getStatisticMethod: async (params: dynamicsObject) => {
    return await axios.get(`/api/owner/statistic`, { params });
  },
  getSupportListMethod: async () => {
    return await axios.get(`/api/support/list`);
  },
  getSupportMethod: async (_id: string) => {
    return await axios.get(`/api/support/${_id}`);
  },
  sendSupportMessageMethod: async (data: dynamicsObject) => {
    return await axios.post(`/api/support`, data);
  },
};
