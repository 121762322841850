import { loadImages } from '../helper';
import meta from '../meta';

export const MAIN_ROUTES = [
  { path: '/auth', name: 'Auth', meta: meta.auth.auth, component: () => import('@/views/auth/Main.vue') },
  { path: '/auth/telegram', name: 'AuthTelegram', meta: meta.auth.telegram, component: () => import('@/views/auth/Telegram.vue') },
  { path: '/auth/access/:accessCode', name: 'AuthAccess', meta: meta.auth.access, component: () => import('@/views/auth/Access.vue'), props: true },

  // { path: '/moderator', name: 'Moderator', meta: meta.moderator.main, component: () => import('@/views/moderator/Main.vue') },
  { path: '/profile', name: 'Profile', meta: meta.profile.main, component: () => import('@/views/profile/Profile.vue') },

  { path: '/channel', name: 'ChannelList', meta: meta.channel.list, component: () => import('@/views/channel/List.vue') },
  { path: '/channel/create', name: 'ChannelCreate', meta: meta.channel.create, component: () => import('@/views/channel/Create.vue') },
  { path: '/channel/:_id', name: 'Channel', meta: meta.channel.main, component: () => import('@/views/channel/Main.vue'), props: true },
  { path: '/channel/:_id/settings', name: 'ChannelSettings', meta: meta.channel.settings, component: () => import('@/views/channel/Settings.vue'), props: true },

  { path: '/subscriber', name: 'Subscriber', meta: meta.subscriber.list, component: () => import('@/views/subscriber/List.vue')},
  
  { path: '/tariff', name: 'Tariff', meta: meta.tariff.list, component: () => import('@/views/tariff/List.vue') },
  { path: '/tariff/:_id', name: 'TariffMain', meta: meta.tariff.main, component: () => import('@/views/tariff/Main.vue'), props: true },

  { path: '/transaction', name: 'Transaction', meta: meta.transaction.list, component: () => import('@/views/transaction/List.vue') },

  { path: '/payment/details', name: 'PaymentDetails', meta: meta.payment.details, component: () => import('@/views/payment/Details.vue') },
  { path: '/payment/success', name: 'PaymentSuccess', meta: meta.telegram.success, component: () => import('@/views/payment/Success.vue') },
  { path: '/payment/error', name: 'PaymentError', meta: meta.telegram.error, component: () => import('@/views/payment/Error.vue') },
  { path: '/payment/tinkoff', name: 'PaymentTinkoff', meta: meta.payment.tinkoff, component: () => import('@/views/payment/Tinkoff.vue') },

  { path: '/autopost', name: 'Autopost', meta: meta.autopost.list, component: () => import('@/views/autopost/List.vue') },
  { path: '/autopost/:_id', name: 'AutopostMain', meta: meta.autopost.main, component: () => import('@/views/autopost/Main.vue'), props: true },

  { path: '/promocode', name: 'Promocode', meta: meta.promocode.main, component: () => import('@/views/promocode/List.vue') },
  { path: '/promocode/generation', name: 'PromocodeGeneration', meta: meta.promocode.generation, component: () => import('@/views/promocode/Generation.vue') },

  { path: '/mailing', name: 'Mailing', meta: meta.mailing.list, component: () => import('@/views/mailing/List.vue') },

  { path: '/partner', name: 'Partner', meta: meta.partner.main, component: () => import('@/views/partner/List.vue') },

  { path: '/referral', name: 'Referral', meta: meta.referral.main, component: () => import('@/views/referral/Main.vue') },

  { path: '/card/confirmed', name: 'CardConfirmed', meta: meta.card.confirmed, component: () => import('@/views/card/Confirmed.vue') },
  
  { path: '/info/policy', name: 'InfoPolicy', meta: meta.info.policy, component: () => import('@/views/info/Policy.vue') },
  { path: '/info/policy/payment', name: 'InfoPolicyPayment', meta: meta.info.policyPayment, component: () => import('@/views/info/PolicyPayment.vue') },
  { path: '/info/offer', name: 'InfoOffer', meta: meta.info.offer, component: () => import('@/views/info/Offer.vue') },
  { path: '/info/legal', name: 'InfoLegal', meta: meta.info.legal, component: () => import('@/views/info/Legal.vue') },
  
  { path: '/telegram/payment/:channel_id', name: 'TelegramPayment', meta: meta.telegram.payment, component: () => import('@/views/telegram/Payment.vue'), props: true },
  { path: '/telegram/subscription', name: 'TelegramSubscription', meta: meta.telegram.subscription, component: () => import('@/views/telegram/Subscription.vue'), props: true },
  
  { path: '/newyear/:token', name: 'NewYear', meta: meta.newyear.index, component: () => import('@/views/newyear/index.vue'), props: true, beforeEnter(to: any, from: any, next: any) { loadImages(); next() } },

  { path: "/:catchAll(.*)", redirect: '/channel' },
]

export const OWNER_ROUTES = [
  { path: '/owner', name: 'OwnerMain', meta: meta.owner.main, component: () => import('@/views/owner/Main.vue') },
  { path: '/owner/auth/:_id', name: 'OwnerAuth', meta: meta.owner.auth, component: () => import('@/views/owner/Auth.vue'), props: true },
  { path: '/owner/admin', name: 'OwnerAdmin', meta: meta.owner.admin, component: () => import('@/views/owner/Admin.vue') },
  { path: '/owner/channel', name: 'OwnerChannel', meta: meta.owner.channel, component: () => import('@/views/owner/Channel.vue') },
  { path: '/owner/subscriber', name: 'OwnerSubscriber', meta: meta.owner.subscriber, component: () => import('@/views/owner/Subscriber.vue') },
  { path: '/owner/tariff', name: 'OwnerTariff', meta: meta.owner.tariff, component: () => import('@/views/owner/Tariff.vue') },
  { path: '/owner/transaction', name: 'OwnerTransaction', meta: meta.owner.transaction, component: () => import('@/views/owner/Transaction.vue') },
  { path: '/owner/promocode', name: 'OwnerPromocode', meta: meta.owner.promocode, component: () => import('@/views/owner/Promocode.vue') },
  { path: '/owner/partner', name: 'OwnerPartner', meta: meta.owner.partner, component: () => import('@/views/owner/Partner.vue') },
  { path: '/owner/referral', name: 'OwnerReferral', meta: meta.owner.referral, component: () => import('@/views/owner/Referral.vue') },
  { path: '/owner/support', name: 'OwnerSupportList', meta: meta.owner.support, component: () => import('@/views/owner/SupportList.vue') },
  { path: '/owner/support/:_id', name: 'OwnerSupport', meta: meta.owner.support, component: () => import('@/views/owner/Support.vue'), props: true },
  { path: '/owner/order', name: 'OwnerOrder', meta: meta.owner.order, component: () => import('@/views/owner/Order.vue') },
  { path: '/owner/advertising', name: 'OwnerAdvertising', meta: meta.owner.advertising, component: () => import('@/views/owner/Advertising.vue') },

  { path: "/owner/:catchAll(.*)", redirect: '/owner' },
]