import axios from "axios";

export default {
  getSubscriberListMethod: async (channel: string) => {
    return await axios.get("/api/mailing/subscriber/list", {
      params: { channel }
    });
  },
  getSupportListMethod: async () => {
    return await axios.get("/api/support/mailing/list");
  },
};
