import auth from './auth';
import file from './file';
import modal from './modal';
import table from './table';
import owner from './owner';
import excel from './excel';

export default {
  auth,
  file,
  modal,
  table,
  owner,
  excel,
}