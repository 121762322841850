import axios from "axios";

export default {
  getUserMethod: async (token: string) => {
    return await axios.get(`/api/telegram/newyear/${token}`);
  },
  answerMethod: async (user: string, question: number, answer: number) => {
    return await axios.post(`/api/telegram/newyear/question`, { user, question, answer });
  },
  resultMethod: async (user: string) => {
    return await axios.post(`/api/telegram/newyear/result`, { user });
  },
};
