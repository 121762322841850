import store from '@/store';

window.addEventListener('online',  updateOnlineStatus);
window.addEventListener('offline', updateOnlineStatus);

function updateOnlineStatus() {
  if (!navigator.onLine) {
    store.commit("createNotification", {
      status: "error",
      message: `Ой! Кажется с твоим интернет-соединением что-то случилось!`,
    });
  } else {
    store.commit("createNotification", {
      status: "success",
      message: `Интернет-соединение восстановлено! Рад твоему возвращению!`,
    });
  }
}