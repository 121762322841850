import axios from "axios";
import botpay from "./botpay";

export default {
  botpay,
  getDetailsMethod: async (token: string) => {
    const request = axios.create();
    return await request({
      url: `/api/payment/details`,
      data: {},
      method: "GET",
      headers: {
        Authorization: token,
      },
    });
  },
  initialTinkoff: async (email: string, product: string) => {
    const request = axios.create();
    return await request({
      url: `/api/v2/payment/tinkoff`,
      data: { email, product },
      method: "POST",
    });
  },
  initialPaymentMethod: async (
    payment: string,
    token: string,
    tariff: string,
    promocode?: string | null,
    auto = false
  ) => {
    const request = axios.create();
    return await request({
      url: `/api/payment/initial`,
      data: { tariff, promocode, payment, auto },
      method: "POST",
      headers: {
        Authorization: token,
      },
    });
  },
};
