const images = [
  '/images/newyear/santa.webp',
  '/images/newyear/background.webp',
  '/images/newyear/comment-bottom.webp',
  '/images/newyear/forest.webp',
  '/images/newyear/snow.webp',
  '/images/newyear/started-bottom.webp',
  '/images/newyear/gifts/1.webp',
  '/images/newyear/gifts/2.webp',
  '/images/newyear/gifts/3.webp',
  '/images/newyear/gifts/4.webp',
  '/images/newyear/gifts/5.webp',
  '/images/newyear/gifts/6.webp',
  '/images/newyear/gifts/7.webp',
  '/images/newyear/gifts/8.webp',
  '/images/newyear/gifts/9.webp',
  '/images/newyear/gifts/10.webp',
  '/images/newyear/messages/1.webp',
  '/images/newyear/messages/2.webp',
  '/images/newyear/messages/3.webp',
  '/images/newyear/messages/4.webp',
  '/images/newyear/messages/5.webp',
  '/images/newyear/messages/6.webp',
  '/images/newyear/messages/7.webp',
  '/images/newyear/messages/8.webp',
  '/images/newyear/messages/9.webp',
  '/images/newyear/messages/10.webp',
  '/images/newyear/result/button.webp',
  '/images/newyear/result/3.webp',
  '/images/newyear/result/7.webp',
  '/images/newyear/result/10.webp',
]

export const loadImages = () => {
  for (const src of images) {
    const img = new Image();
    img.src = src;
  }
}