import { dynamicsObject } from "@/interfaces";
import { CalendarDates } from '@/interfaces/common/calendar';
import { useAPI } from "@/use";
import { Commit } from "vuex";

interface State {
  drop: { options: dynamicsObject, target: string } | null,
  calendar: { options: dynamicsObject, dates: CalendarDates } | null,
  innerWidth: number,
  botpay: dynamicsObject | null,
}

export default {
  state: {
    drop: null,
    calendar: null,
    innerWidth: window.innerWidth,
    botpay: null,
  },
  mutations: {
    createDropElement(state: State, drop: State['drop']) {
      if (state.drop) return state.drop = null;
      state.drop = drop;
    },
    destroyDropElement(state: State) {
      state.drop = null;
    },
    createCalendar(state: State, calendar: State['calendar']) {
      if (state.calendar) return state.calendar = null;
      state.calendar = calendar;
    },
    destroyCalendar(state: State) {
      state.calendar = null;
    },
    resizeWidth(state: State, width: number) {
      state.innerWidth = width;
    },
    updateBotpayPayment(state: State, botpay: dynamicsObject) {
      state.botpay = botpay;
    },
    clearBotpayPayment(state: State) {
      state.botpay = null;
    }
  },
  actions: {
    async getBotpayPaymentMethod({ commit }: { commit: Commit }) {
      const result = await useAPI().payment.botpay.getMethod();
      commit('updateBotpayPayment', result.data);
    }
  },
  getters: {
    drop: (state: State) => state.drop,
    botpay: (state: State) => state.botpay,
    calendar: (state: State) => state.calendar,
    innerWidth: (state: State) => state.innerWidth,
  }
}