import { dynamicsObject } from "@/interfaces";
import { Channel } from "@/interfaces/channel/channel.dto";
import axios from "axios";

export default {
  getMethod: async (_id: string) => {
    return await axios.get("/api/channel/" + _id);
  },
  getUnActiveMethod: async (_id?: string) => {
    return await axios.get("/api/channel/unactive", {
      params: { _id }
    });
  },
  getOtherMethod: async (_id: string) => {
    return await axios.get(`/api/channel/${_id}/other`);
  },
  getStatisticMethod: async (_id: string, params: dynamicsObject) => {
    return await axios.get(`/api/channel/${_id}/statistic`, { params });
  },
  getTelegramMethod: async (_id: string) => {
    return await axios.get("/api/channel/telegram/" + _id);
  },
  getAllMethod: async () => {
    return await axios.get("/api/channel/all");
  },
  getUserAllMethod: async (_id: string) => {
    return await axios.get("/api/channel/user/all", { params: { _id } });
  },
  getListMethod: async (params: { limit: number, page: number, search: string }) => {
    return await axios.get("/api/channel/list", { params });
  },
  updateChildrenMethod: async (data: { _id: string, children: Channel.Dto['children'] }) => {
    return await axios.patch("/api/channel/children", data);
  },
  updateMethod: async (data: dynamicsObject) => {
    return await axios.patch("/api/channel", data);
  },
  togglePaymentMethod: async (data: dynamicsObject) => {
    return await axios.patch("/api/channel/payment", data);
  },
  activeMethod: async (_id: string) => {
    return await axios.patch("/api/channel/active", { _id });
  },
  generateChannelLinkMethod: async (_id: string) => {
    return await axios.patch("/api/channel/link", { _id });
  },
  deleteMethod: async (_id: string) => {
    return await axios.delete("/api/channel/" + _id);
  },
};
