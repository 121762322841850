import { dynamicsObject } from "@/interfaces";
import axios from "axios";

export default {
  getAllMethod: async (params = {}) => {
    return await axios.get("/api/tariff/all", {
      params
    });
  },
  getListMethod: async (params = {}) => {
    return await axios.get("/api/tariff/list", {
      params
    });
  },
  createMethod: async (data: dynamicsObject) => {
    return await axios.post("/api/tariff", data);
  },
  updateMethod: async (data: dynamicsObject) => {
    return await axios.patch("/api/tariff", data);
  },
  updateReferralMethod: async (data: dynamicsObject) => {
    return await axios.patch("/api/tariff/referral", data);
  },
  toggleReferralStatusMethod: async (data: dynamicsObject) => {
    return await axios.patch("/api/tariff/referral/status", data);
  },
  deleteMethod: async (_id: string) => {
    return await axios.delete("/api/tariff/" + _id);
  },
};
