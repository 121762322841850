import { dynamicsObject } from "@/interfaces";
import axios from "axios";

export default {
  getPaymentMethod: async () => {
    return await axios.get("/api/payment");
  },
  updatePaymentMethod: async (data: dynamicsObject) => {
    return await axios.patch("/api/payment", data);
  },
  updateMethod: async (data: dynamicsObject) => {
    return await axios.patch("/api/user", data);
  },
  updateAccessCodeMethod: async () => {
    return await axios.patch("/api/user/access/code");
  },
};
