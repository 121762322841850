import { Commit } from 'vuex';
import { User } from '@/interfaces/user/user.dto';

import router from '@/router';
import { dynamicsObject } from '@/interfaces';
import { useAPI } from '@/use';
import { File } from '@/interfaces/file/file.dto';
import { PaymentDetails } from '@/interfaces/payment/payment.dto';

interface State {
  Authorization: string | null,
  user: User.Dto | null | dynamicsObject
}

function setLocalstorage(accessToken: string) {
  localStorage.setItem('Authorization', accessToken);
}
function removeLocalStorage() {
  localStorage.removeItem('Authorization');
}

function setSessionStorage(accessToken: string) {
  sessionStorage.setItem('Authorization', accessToken);
}
function removeSessionStorage() {
  sessionStorage.removeItem('Authorization');
}

export default {
  state: {
    Authorization: sessionStorage.getItem('Authorization') || localStorage.getItem('Authorization'),
    user: null as User.Dto | null,
  },
  getters: {
    Authorization: (state: State) => state.Authorization,
    user: (state: State) => state.user,
  },
  mutations: {
    updateUserDataModel(state: dynamicsObject, user: User.Dto) {
      const keys = Object.typedKeys(user);
      for (const key of keys) {
        state.user[key] = user[key];
      }
      state.Authorization = user.accessToken;
      setLocalstorage(user.accessToken);
    },
    updateUserData(state: State, user: User.Dto) {
      state.Authorization = user.accessToken;
      state.user = user;
      setLocalstorage(user.accessToken);
    },
    updateUserAvatar(state: State, data: { doc: File.Dto }) {
      if (!state.user) return;
      if (!state.user.avatar) return state.user.avatar = data.doc;
      state.user.avatar.src = data.doc.src;
    },
    updatePaymentDetails(state: State, payment: PaymentDetails.Dto) {
      if (!state.user) return;
      state.user.payment = payment;
    },
    updateUserBalance(state: State, data: { type: 'partner' | 'common', value: number }) {
      if (!state.user) return;
      if (data.type === 'partner')
        state.user.partner.balance = data.value;
      if (data.type === 'common')
        state.user.balance = data.value;
    },
    logout(state: State) {
      state.Authorization = null;
      state.user = null;
      removeLocalStorage();
      router.push('/auth');
    },
    loginOwner(state: State, { accessToken, query }: { accessToken: string, query: dynamicsObject }) {
      let route = '/channel';
      if (query.channel)
        route += `/${query.channel}`
      
      state.Authorization = accessToken;
      setSessionStorage(accessToken);
      router.push(route);
    }
  },
  actions: {
    onLogin({ commit }: { commit: Commit }, user: User.Dto) {
      commit('updateUserData', user);
      router.push('/channel');
    },
    logoutAction({ commit }: { commit: Commit }) {
      commit('logout');
    },
    async checkAuthorization({ state }: { state: State }) {
      const result = await useAPI().common.auth.authenticationMethod();
      if (!result) return;
      
      state.user = result.data;
      state.Authorization = result.data.accessToken;
      if (!sessionStorage.getItem('Authorization'))
        setLocalstorage(result.data.accessToken);
    },
  }
}