import { dynamicsObject } from "@/interfaces";
import { Notification } from "@/interfaces/notification/notification.dto";

interface State {
  notification: Notification.Dto | null
  generalNotification: dynamicsObject | null
}

export default {
  state: {
    notification: {},
    generalNotification: {}
  },
  mutations: {
    createNotification(state: State, notification: Notification.Dto) {
      state.notification = notification;
    },
    destroyNotification(state: State) {
      state.notification = null;
    },
    createGeneralNotification(state: State, notification: dynamicsObject) {
      state.generalNotification = notification;
    },
    destroyGeneralNotification(state: State) {
      state.generalNotification = null;
    }
  },
  getters: {
    notification: (state: State) => state.notification,
    generalNotification: (state: State) => state.generalNotification
  }
}