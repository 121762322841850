import axios from "axios";

export default {
  updateAvatarUserMethod: async (data: FormData) => {
    return await axios.patch("/api/file/user/avatar", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  updateImageChannelMethod: async (channel: string, data: FormData) => {
    return await axios.patch("/api/file/channel/image", data, {
      headers: { "Content-Type": "multipart/form-data" },
      params: { channel }
    });
  },
  updateGreetingsImageChannelMethod: async (channel: string, data: FormData) => {
    return await axios.patch("/api/file/channel/greetings/image", data, {
      headers: { "Content-Type": "multipart/form-data" },
      params: { channel }
    });
  },
  deleteTaskAttachmentMethod: async (data: { task: string, _id: string }) => {
    return await axios.delete(`/api/file/task/${data.task}/${data._id}`);
  },
  deleteAvatarUserMethod: async () => {
    return await axios.delete(`/api/file/user/avatar`);
  },
  deleteAutopostAttachmentMethod: async (_id: string) => {
    return await axios.delete(`/api/file/autopost/attachment/${_id}`);
  },
  deleteGreetingsImageChannelMethod: async (_id: string) => {
    return await axios.delete(`/api/file/channel/greetings/${_id}`);
  },
};
