import axios from "axios";

export default {
  getMethod: async () => {
    return await axios.get('/api/payment/botpay');
  },
  addCustomerMethod: async () => {
    return await axios.post('/api/payment/botpay/card/binding');
  },
  removeCardMethod: async () => {
    return await axios.delete('/api/payment/botpay/card');
  },
};
