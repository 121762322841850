import subscriber from './modules/subscriber';
import promocode from './modules/promocode';
import payment from './modules/payment';
import partner from './modules/partner';
import channel from './modules/channel';
import mailing from './modules/mailing';
import newyear from './modules/newyear';
import common from './modules/common';
import tariff from './modules/tariff';
import user from './modules/user';

export default {
  subscriber,
  promocode,
  payment,
  partner,
  channel,
  mailing,
  newyear,
  common,
  tariff,
  user,
}