import { dynamicsObject } from "@/interfaces";
import axios, { AxiosResponse } from "axios";

export default {
  getTableData: async (request: string, params: dynamicsObject): Promise<AxiosResponse<Array<dynamicsObject>, any> & { total: number }> => {
    return await axios.get(request, { params });
  },
  getFilterListMethod: async (request: string) => {
    return await axios.get(request);
  },
};
