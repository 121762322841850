import axios from "axios";

export default {
  getCountMethod: async () => {
    return await axios.get("/api/partner/count");
  },
  incViewMethod: async (code: string) => {
    return await axios.patch("/api/partner/view", { code });
  },
};
