import axios from "axios";
import { dynamicsObject } from "@/interfaces";

export default {
  authenticationMethod: async () => {
    return await axios.get("/api/authentication");
  },
  authenticationOwnerMethod: async (_id: string) => {
    return await axios.get("/api/authentication/owner", {
      params: { _id }
    });
  },
  loginAccessCodeMethod: async (accessCode: string) => {
    return await axios.post("/api/login/access/code", { accessCode });
  },
  loginMethod: async (params: dynamicsObject) => {
    return await axios.get("/api/login", { 
      params
    });
  },
  loginTestMethod: async (query: dynamicsObject) => {
    return await axios.get(`/api/login/${query.email}/${query.token}`);
  },
  logoutMethod: async () => {
    return await axios.post("/api/auth/logout");
  },
};
