import axios, { AxiosRequestConfig } from "axios";

export default {
  onSubmit: async (options: AxiosRequestConfig) => {
    return await axios(options);
  },
  uploadFileMethod: async (options: AxiosRequestConfig) => {
    return await axios(options);
  },
};
