import router from "@/router";
import store from "@/store";
import axios from "axios";

const callNotification = (message: string, type: string, timeout = 5000) => {
  store.commit("createGeneralNotification", {
    message, type, timeout
  });
}

axios.interceptors.request.use(
  (config) => {
    const token = store.getters.Authorization;
    if (token && config.headers) config.headers.Authorization = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.data.message)
      callNotification(response.data.message, 'static');
    store.commit('togglePendingRequest', false);
    return response.data;
  },
  (error) => {
    if (!error.response) return Promise.reject(error);
    store.commit('togglePendingRequest', false);

    if (error.response.config.url === '/api/authentication') return;
    if (error.response.status === 401) {
      callNotification(error.response.data.message, 'error');
    } else if (error.response.status === 403) {
      callNotification(error.response.data.message, 'error');
      store.commit('destroyModal');
      if (error.response.config.method === 'get') router.back();
    } else if (!error.response.data.status && error.response.data.message && error.response.status !== 401) {
      callNotification(error.response.data.message, 'error');
    } else if (error.response.status === 500) {
      callNotification('Упс, что-то пошло не так :(', 'error', 8000);
    }
    return Promise.reject(error);
  }
);
